import React, { useEffect, useContext, useState, useMemo } from "react";
import UITopic from "ui/UITopic";
import { useHistory } from "react-router-dom";
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import CountContext from "contexts/contextCount";
import TopicsContext from "contexts/contextTopics";
import LiveCacheContext from "data/contextLiveCache";
import {
  randomString,
  timestamp,
  timestampZero,
  timestampCalculation,
  isMobileTablet
} from "hooks/helper";
import PersonasContext from "contexts/contextPersonas";
import {
  BsArchive,
  BsFolder,
  BsFolderMinus,
  BsFolderPlus,
  BsThreeDotsVertical
} from "react-icons/bs";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";
import { VscFolderOpened } from "react-icons/vsc";
import GetInitials from "../utils/GetInitials";
import CalcStringColor from "../utils/CalcStringColor";
import GrabCharsFromWords from "../utils//GrabCharsFromWords";
import "ui/UIButtons.css";
import { List, AutoSizer } from "react-virtualized";
import { dex_action } from "data/dexUtils";
import TopicStateProvider from "contexts/TopicStateProvider";

const UITopicList = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { countState } = useContext(CountContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  let history = useHistory();
  let targetdialog = sessionStorage.getItem("targetdialog");
  let targetmtopic = sessionStorage.getItem("targetmtopic");
  const [collectionCount, setCollectionCount] = useState({});
  const MAX_PINS = 5;
  const [topicDisplayList, setTopicDisplayList] = useState([]);
  const itemHeight = 68;
  const [scrollTop, setScrollTop] = useState(0);
  const [autoSizerHeight, setAutoSizerHeight] = useState(0); // Initialize with 0
  
  const handleAutoSizerResize = ({ height }) => {
    // Update the autoSizerHeight when the AutoSizer resizes
    setAutoSizerHeight(height);
  };

  const possibleCollections = useMemo(
    () => (mpersona) => {
      let list = [
        ...(topicsState.topics || [])
          .filter(
            (t) =>
              t.mpersona === mpersona &&
              (t?.subprops?.archived || "false") ===
                (globalState.displaying_archive ? "true" : "false")
          )
          .map((t) => getIdentity(t))
        // ...(subscriptionsState.subscriptions || [])
        // .filter(
        //   (t) =>
        //     t.mpersona === mpersona &&
        //     (t?.subprops?.archived || "false") ===
        //       (globalState.displaying_archive ? "true" : "false")
        // )
        // .map((t) => getIdentity(t))
        // ...(dialogsState.dialogs || [])
        //   .filter(
        //     (t) =>
        //       t.mpersona === mpersona &&
        //       (t?.subprops?.archived || "false") ===
        //         (globalState.displaying_archive ? "true" : "false")
        //   )
        //   .map((t) => getIdentity(t))
      ];
      return [...new Set(list)];
    },
    [globalState.displaying_archive, topicsState.topics]
  );

  const sortArr = (data) => {
    data.sort((a, b) => {
      const testdateA =
        (a.subprops &&
          a.subprops?.pinned === "true" &&
          timestampCalculation(
            a?.subprops?.pindate ||
              countState.last_visible_date?.[`${a.mtopic}_${a.mpersona}`] ||
              a.last_visible_date ||
              timestamp(),
            "y",
            10
          )) ||
        countState.last_visible_date?.[`${a.mtopic}_${a.mpersona}`] ||
        a.last_visible_date ||
        a?.props?.creationdate ||
        "1970";
      const dateA =
        (a.subprops?.pinned === "true" && a.subprops?.pindate) ||
        a.last_visible_date ||
        a.props?.creationdate ||
        "1970-01-01";
      const testdateB =
        (b.subprops &&
          b.subprops?.pinned === "true" &&
          timestampCalculation(
            b?.subprops?.pindate ||
              countState.last_visible_date?.[`${b.mtopic}_${b.mpersona}`] ||
              b.last_visible_date ||
              timestamp(),
            "y",
            10
          )) ||
        countState.last_visible_date?.[`${b.mtopic}_${b.mpersona}`] ||
        b.last_visible_date ||
        b?.props?.creationdate ||
        "1970";
      const dateB =
        (b.subprops?.pinned === "true" && b.subprops?.pindate) ||
        b.last_visible_date ||
        b.props?.creationdate ||
        "1970-01-01";
      if (
        a.subprops?.pinned === "true" &&
        (!b.subprops?.pinned || b.subprops.pinned === "false")
      ) {
        return -1;
      } else if (
        (!a.subprops?.pinned || a.subprops.pinned === "false") &&
        b.subprops?.pinned === "true"
      ) {
        return 1;
      } else if (
        a.subprops?.pinned === "true" &&
        b.subprops?.pinned === "true"
      ) {
        return new Date(testdateB) - new Date(testdateA);
      } else {
        return new Date(dateB) - new Date(dateA);
      }
    });
    // data.sort((a, b) => {
    //   if (a.order.startsWith("3000-") && b.order.startsWith("3000-")) {
    //     return a.order > b.order ? 1 : -1;
    //   } else {
    //     return a.order < b.order ? 1 : -1;
    //   }
    // })
    return data;
  };

  const toggleArchive = useMemo(
    () => () => {
      globalDispatch({
        type: "TOGGLE_ARCHIVE"
      });
    },
    []
  );

  const activeAreaButton = useMemo(
    () => (display) => {
      return (
        <div
          className="UI-collection"
          type="ezist"
          style={
            globalState.isLoggedIn
              ? {
                  backgroundColor: "var(--modal_footer_background_color)",
                  cursor: "pointer"
                }
              : { display: "none" }
          }
          onClick={() => {
            toggleArchive();
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                width: "4rem",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                className="avatar-string"
                style={{ color: "black", backgroundColor: "white" }}
              >
                {
                  //   globalState?.prefs?.showFolders ? (
                  //   "AR"
                  // ) :
                  globalState?.displaying_archive ? (
                    <RiInboxUnarchiveLine />
                  ) : (
                    <RiInboxArchiveLine />
                  )
                }
              </div>
            </div>
          </div>
          <div className="UI-topic-inner" style={{ width: "100%" }}>
            <div className="UI-topic-tdn hide-scrollbar">
              <span>
                {globalState?.displaying_archive ? "ACTIVE" : "ARCHIVED"}
              </span>
            </div>
            <span className="mute-icon"></span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {globalState?.displaying_archive ? (
                globalState?.prefs?.showFolders ? (
                  <VscFolderOpened
                    style={{ width: "1.5em", height: "1.5em" }}
                  />
                ) : (
                  ""
                )
              ) : globalState?.prefs?.showFolders ? (
                <BsArchive style={{ width: "1.5em", height: "1.5em" }} />
              ) : (
                ""
              )}
            </div>
          </div>
          <span className="UI-icon-dropdown">
            <BsThreeDotsVertical style={{ opacity: "0" }} />
          </span>
        </div>
      );
    },
    [
      globalState?.displaying_archive,
      globalState?.prefs?.showFolders,
      toggleArchive
    ]
  );

  useEffect(() => {
    if (props.collectionDisplay === "current") {
      return;
    }
    if (!globalState.persona?.mpersona) {
      process.env?.REACT_APP_DEBUG_THIS?.includes("UITopicList") &&
        console.log("[UITopicList] do not know mpersona", globalState);
      return;
    }
    let collections = possibleCollections(globalState.persona?.mpersona);
    let collectionState = { ...liveCacheState?.collectionState } || {};
    process.env.REACT_APP_DEBUG_THIS &&
      console.log("[UITopicList] collectionState before", collectionState);

    collections.forEach((key) => {
      if (!collectionState[key]) {
        collectionState = {
          ...collectionState,
          [`${key}`]: {
            [`${globalState.persona.mpersona}`]: {
              open: props.collectionDisplay === "collapse" ? true : false
            }
          }
        };
      } else if (!collectionState[key][globalState.persona.mpersona]) {
        collectionState[key] = {
          ...collectionState[key],
          [`${globalState.persona.mpersona}`]: {
            open: props.collectionDisplay === "collapse" ? true : false
          }
        };
      } else {
        collectionState[key][globalState.persona.mpersona] = {
          open: props.collectionDisplay === "collapse" ? true : false
        };
      }
    });
    liveCacheDispatch({
      type: "SET",
      values: {
        cache: { collectionState: collectionState }
      }
    });
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT_IF_DIFF",
        values: {
          db: databaseState.dexUser,
          table: "user",
          primaryKey: { key: "collectionstate" },
          doc: { key: "collectionstate", value: collectionState }
        }
      });
    props.setCollectionDisplay("current");

    process.env.REACT_APP_DEBUG_THIS &&
      console.log("[UITopicList] collectionState after", collectionState);
  }, [
    // props.collectionDisplay,
    databaseState.dexUser,
    props,
    // globalState,
    possibleCollections,
    liveCacheState?.collectionState,
    liveCacheDispatch,
    databaseDispatch
  ]);

  useEffect(() => {
    if (targetmtopic) {
      topicsState.topics &&
        topicsState.topics.map((sub) => {
          if (sub.mtopic && sub.mtopic === targetmtopic) {
            sessionStorage.removeItem("targetmtopic");
            sessionStorage.removeItem("targetdialog");
            // if targetmtopic exists, navigate directly to that topic
            // switch to the firsctt subscribtion's persona
            let mpersona = sub.mpersona;
            let persona =
              mpersona &&
              personasState.personas.filter((p) => p.mpersona === mpersona)[0]
                ?.persona;
            persona &&
              globalDispatch({
                type: "SET_PERSONA",
                values: { persona: { mpersona: mpersona, persona: persona } }
              });
            console.log("uitopiclist going to uimessagelist");
            if (isMobileTablet())
              history.push("/UIMessageList", {
                subscription: sub
              });
            else {
              topicsDispatch({
                type: "SET_SHOWTOPIC",
                values: { showTopic: { subscription: sub } }
              });
            }
          }
        });
    }

    return () => {};
  }, [
    // subscriptionsState.subscriptions,
    // dialogsState.dialogs,
    personasState.personas,
    targetdialog,
    targetmtopic,
    topicsState.topics,
    history,
    globalDispatch
  ]);

  const [listItems, setListItems] = useState([]);
  // const [listCategories, setListCategories] = useState([]);

  const getIdentity = (sub) => {
    let theIdentity =
      sub?.props?.topiccollection ||
      sub?.props?.tag ||
      sub?.visibility ||
      sub?.props?.visibility;
    theIdentity = theIdentity.toString();
    theIdentity = theIdentity.toLowerCase();
    return theIdentity;
  };

  
  const assignCollection = (sub) => {
    if (liveCacheState?.collectionState[getIdentity(sub)])
      if (
        !liveCacheState?.collectionState[getIdentity(sub)][
          globalState.persona.mpersona
        ]?.open ||
        sub.subprops?.pinned === "true"
      )
        return true;
      else return false;
    else return true;
  };

  const toggleCollectionState = useMemo(
    () => (topiccollection, mpersona) => {
      let collectionState = { ...liveCacheState?.collectionState } || {};
      collectionState[topiccollection] = collectionState[topiccollection] || {};
      collectionState[topiccollection][mpersona] = {
        open: !collectionState[topiccollection][mpersona]?.open
      };
      liveCacheDispatch({
        type: "SET",
        values: { cache: { collectionState: collectionState } }
      });
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT_IF_DIFF",
          values: {
            db: databaseState.dexUser,
            table: "user",
            primaryKey: { key: "collectionstate" },
            doc: { key: "collectionstate", value: collectionState }
          }
        });
      props.setCollectionDisplay("current");
    },
    [
      databaseDispatch,
      databaseState.dexUser,
      liveCacheDispatch,
      liveCacheState?.collectionState,
      props.setCollectionDisplay
    ]
  );

  useEffect(() => {
    let data =
      topicsState.topics &&
      globalState.persona?.mpersona &&
      topicsState.topics.filter(
        (item) => item.mpersona === globalState.persona?.mpersona
      );
    if (data) {
      data = sortArr(data);
      let pinnedList = data
        .filter(
          (i) =>
            i &&
            (!i?.subprops?.archived || "false") ===
              (globalState.displaying_archive !== undefined
                ? globalState.displaying_archive
                  ? "true"
                  : "false"
                : "false")
        )
        .filter((i) => i.subprops?.pinned === "true");
      // pinnedList = sortArr(pinnedList);

      let tagList = [];
      let listArea = data
        .filter(
          (i) =>
            i &&
            (i?.subprops?.archived || "false") ===
              (globalState.displaying_archive !== undefined
                ? globalState.displaying_archive
                  ? "true"
                  : "false"
                : "false")
        )
        .filter((i) => i.subprops?.pinned !== "true");
      listArea.forEach((a) => {
        let tagId = (
          a.props?.topiccollection ||
          a.props?.tag ||
          a.visibility ||
          a.props?.visibility ||
          "no_tag"
        ).toLowerCase();
        !(a.pinned === "true") &&
          tagId !== "no_tag" &&
          !tagList.includes(tagId) &&
          tagList.push(tagId);
      });
      let allFolders = [];
      tagList
        .sort((a, b) => {
          return a < b ? 1 : -1;
        })
        .forEach((tag) => {
          let folder = [];
          if (
            !liveCacheState?.collectionState?.[tag]?.[
              globalState?.persona?.mpersona
            ]?.open
          )
            folder = listArea.filter((a) => {
              if (
                tag ===
                  (
                    a.props?.topiccollection ||
                    a.props?.tag ||
                    a.visibility ||
                    a.props?.visibility ||
                    "no_tag"
                  ).toLowerCase() &&
                a.subprops?.pinned !== "true"
              ) {
                return a;
              }
            });
          let newObj = {
            id: "folder_" + tag,
            mpersona: globalState.persona?.mpersona,
            folderTag: tag
          };
          // folder = sortArr(folder)
          allFolders = [newObj, ...folder, ...allFolders];
        });
      // concat the pins + folders
      allFolders = [...pinnedList, ...allFolders];
      setListItems(allFolders);
    }
  }, [
    topicsState.topics,
    globalState.persona?.mpersona,
    globalState.displaying_archive,
    globalState?.prefs?.showFolders,
    props.share,
    liveCacheState?.collectionState,
    JSON.stringify(countState.last_visible_date)
  ]);

  const togglePin = useMemo(
    () => (topic) => {
      process.env?.REACT_APP_DEBUG_THIS?.includes("UITopicList") &&
        console.log("[UITopicList] toggle pin for topic", topic);
      let pinnedValue = topic.subprops?.pinned === "true" ? "false" : "true";
      let pinDate = pinnedValue === "true" ? timestamp() : "";
      let j = {
        type: "w.t.subprops.set",
        mtopic: topic.mtopic,
        mpersona: topic.mpersona,
        new_props: { pinned: pinnedValue, pindate: pinDate },
        version: globalState.version,
        smid: randomString(8),
        ts_sender: timestampZero(),
        status: "1",
        origin_sender: "registered"
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value?.forEach((t) => {
            if (t.mpersona === topic.mpersona && t.mtopic === topic.mtopic) {
              t.subprops.pinned = pinnedValue;
              t.subprops.pindate = pinDate;
            }
          });
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
    },
    [databaseDispatch, databaseState.dexUser, globalState.version]
  );

  useEffect(() => {
    let pinnedList =
      topicsState?.topics?.filter((topic) => {
        return (
          topic.mpersona === globalState.persona?.mpersona &&
          topic.subprops?.pinned &&
          topic.subprops?.pinned === "true"
        );
      }) || [];
    if (pinnedList?.length > MAX_PINS) {
      let sortedPins = pinnedList.sort((a, b) => {
        return a.subprops?.pindate > b.subprops?.pindate ? 1 : -1;
      });
      // unpin the oldest if there are more than MAX_PINS
      togglePin(sortedPins[0]);
    }
    return () => {};
  }, [topicsState?.topics]);

  useEffect(() => {
    let countSummary = {};
    topicsState?.topics &&
      topicsState.topics
        .filter(
          (t) =>
            ["t", "true", true].includes(t?.subprops?.archived) ===
            (globalState.displaying_archive || false)
        )
        .forEach((t) => {
          // must exclude if topic is in a Suite
          let exclude = [];
          let tag = getIdentity(t);
          if (
            t.mpersona === globalState.persona?.mpersona &&
            !exclude.includes(t.mtopic) &&
            t.subprops?.pinned !== "true" // pinned topics will not be included in collections
          ) {
            if (
              countState?.count &&
              countState?.count[`${t.mtopic}_${t.mpersona}`]
            ) {
              if (countSummary[tag]) {
                countSummary[tag].unread =
                  (countSummary[tag]?.unread ? countSummary[tag].unread : 0) +
                  (countState?.count[`${t.mtopic}_${t.mpersona}`].unread > 0
                    ? 1
                    : 0);
                countSummary[tag].activity = countState?.count[
                  `${t.mtopic}_${t.mpersona}`
                ]?.activity
                  ? countState?.count[`${t.mtopic}_${t.mpersona}`]?.activity >
                    countSummary[tag]?.activity
                    ? countState?.count[`${t.mtopic}_${t.mpersona}`]?.activity
                    : countSummary[tag]?.activity
                  : "1970";
              } else {
                countSummary[tag] = {
                  unread:
                    countState?.count[`${t.mtopic}_${t.mpersona}`]?.unread > 0
                      ? 1
                      : 0,
                  activity:
                    countState?.count[`${t.mtopic}_${t.mpersona}`]?.activity ||
                    "1970"
                };
              }
            }
          }
        });

    // sort by date ascending
    let x;
    let dateArr = Object.entries(countSummary)
      .map((entry) => (x = entry[1].activity))
      .sort()
      .reverse();
    // rebuild collection count summary
    let sortedByAge = {};
    dateArr.forEach((dateEntry) => {
      Object.keys(countSummary).forEach((key) => {
        if (countSummary[key].activity === dateEntry) 
          sortedByAge[key] = JSON.parse(JSON.stringify(countSummary[key]));
      });
    });
    setCollectionCount({ ...sortedByAge });
    process.env?.REACT_APP_DEBUG_THIS?.includes("UITopicList") &&
      console.log("[UITopicList] sorted collections", sortedByAge);
    return () => {};
  }, [
    topicsState.topics,
    JSON.stringify(countState.count),
    liveCacheState.collectionState,
    globalState.persona?.mpersona,
    globalState.displaying_archive
  ]);

  const getCollectionName = (key) => {
    switch (key.toUpperCase()) {
      case "PRIVATE_DIALOG":
        return "DIALOG";
      case "PRIVATE":
        return "PRIVATE";
      case "HIDDEN":
        return "HIDDEN";
      default:
        return key;
    }
  };

  const calcCollectionLogoTransparency = () => {
    let root = document.getElementById("root");
    let tVal = root.style.getPropertyValue("--logo_background_transparency");
    if (tVal === undefined || !tVal) tVal = 0.5;
    tVal = 1 - tVal;
    tVal = tVal / 10 + 0.1;
    return tVal;
  };

  const getHeaderBarColor = (tag, initialsStr = "") => {
    let backColorProp =
      tag?.toUpperCase() === "PRIVATE_DIALOG" ? "dialog" : tag.toLowerCase();
    backColorProp =
      "--main_screen_topic_type_" + backColorProp + "_background_color";
    let currentColorProp = document
      .getElementById("root")
      .style.getPropertyValue(backColorProp);
    if (currentColorProp.length === 0) {
      initialsStr.length === 0 &&
        (initialsStr = GrabCharsFromWords(
          getCollectionName(tag),
          2,
          0,
          1,
          "."
        ).toUpperCase());
      const [red, green, blue] = CalcStringColor(initialsStr);
      backColorProp = `rgba(${red}, ${green}, ${blue}, ${calcCollectionLogoTransparency()})`;
    } else {
      backColorProp = currentColorProp;
    }
    return backColorProp;
  };

  useEffect(() => {
    let data =
      topicsState.topics &&
      globalState.persona?.mpersona &&
      topicsState.topics.filter(
        (item) => item.mpersona === globalState.persona?.mpersona
      );
    if (data && topicsState.labelDisplay) {
      let displayList = [];
      if (!globalState.displaying_archive)
        data = data.filter((item) => item.subprops?.archived !== "true");
      else data = data.filter((item) => item.subprops?.archived === "true");
      let theMTopicsArr = []
      for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
        if (typeof globalState.prefs?.labelNamesList[i].labels[0] === 'object') {
          for (let j = 0; j < globalState.prefs?.labelNamesList[i].labels?.length; j++) {
            if (globalState.prefs?.labelNamesList[i].labels[j]?.text.includes(topicsState.labelDisplay)) {
              if (globalState.prefs?.labelNamesList[i].mpersona === globalState.persona.mpersona) {
                if (!theMTopicsArr.includes(globalState.prefs?.labelNamesList[i].mtopic))
                  theMTopicsArr = [...theMTopicsArr, globalState.prefs?.labelNamesList[i].mtopic]
              }
            }
          }
        }
        else if (globalState.prefs?.labelNamesList[i].labels.includes(topicsState.labelDisplay)) {
          if (globalState.prefs?.labelNamesList[i].mpersona === globalState.persona.mpersona) {
            if (!theMTopicsArr.includes(globalState.prefs?.labelNamesList[i].mtopic))
              theMTopicsArr = [...theMTopicsArr, globalState.prefs?.labelNamesList[i].mtopic]
          }
        }
      }
      data
        .forEach((t) => {
          for (let j = 0; j < theMTopicsArr?.length; j++) {
            if (t.mtopic === theMTopicsArr[j]) {
              displayList = [...displayList, t]
            }
          }
          displayList = sortArr(displayList);
        })
      setTopicDisplayList(displayList)
    }
    else
      if (data && topicsState.flagDisplay) {
        let displayList = [];
        if (!globalState.displaying_archive)
          data = data.filter((item) => item.subprops?.archived !== "true");
        else data = data.filter((item) => item.subprops?.archived === "true");
        let theMTopicsArr = []
        for (let i = 0; i < globalState.prefs?.clientStar?.length; i++) {
          if (globalState.prefs?.clientStar[i].mpersona === globalState.persona.mpersona) {
            if (!theMTopicsArr.includes(globalState.prefs?.clientStar[i]?.mtopic)) {
              const theMTopic = globalState.prefs?.clientStar[i]?.mtopic
              if (theMTopicsArr?.length > 0)
                theMTopicsArr = [...theMTopicsArr, theMTopic]
              else theMTopicsArr = [theMTopic]
            }
          }
        }
        data
          .forEach((t) => {
            for (let j = 0; j < theMTopicsArr?.length; j++) {
              if (t.mtopic === theMTopicsArr[j]) {
                displayList = [...displayList, t]
              }
            }
            displayList = sortArr(displayList);
          })
        setTopicDisplayList(displayList)
      }
      else
        if (data && topicsState.unreadDisplay) {
      let displayList = [];
      if (!globalState.displaying_archive)
        data = data.filter((item) => item.subprops?.archived !== "true");
      else data = data.filter((item) => item.subprops?.archived === "true");
      data
        .forEach((t) => {
          if (
            countState?.count &&
            countState?.count[`${t.mtopic}_${t.mpersona}`]?.unread > 0
          ) {
            displayList = [...displayList, t]
          }
          displayList = sortArr(displayList);
        })
          setTopicDisplayList(displayList)
    }
    else if (data && !globalState?.prefs?.showFolders) {
      data = sortArr(data);
      let displayList;
      if (globalState.displaying_archive === true) {
        displayList = data.filter((item) => item.subprops?.archived === "true");
      } else {
        displayList = data.filter((item) => item.subprops?.archived !== "true");
      }
      displayList = displayList.filter((item) => assignCollection(item));

      let possible = possibleCollections(globalState.persona?.mpersona);
      let keysCollection = liveCacheState?.collectionState
        ? Object.keys(liveCacheState?.collectionState)
          .filter((key) => !key.startsWith("_"))
          .filter((key) => possible.includes(key))
        : [];
      // test if the planned collection correlates with the chronologically sorted collection.
      // simple test but efficient enough to point out bugs (chron order will be broken)
      let keysToUse = [];
      if (
        keysCollection &&
        keysCollection.length === Object.keys(collectionCount)?.length
      ) {
        keysToUse = Object.keys(collectionCount);
      } else {
        keysToUse = keysCollection;
      }
      let allCollectionObj = []
      let myTestObj = {};
      keysToUse.forEach((key) => {
        if (
          liveCacheState?.collectionState[key] &&
          liveCacheState?.collectionState[key][globalState.persona?.mpersona] &&
          liveCacheState?.collectionState[key][globalState.persona?.mpersona]
            .open
        ) {
          myTestObj = {
            id: "collection_" + key,
            key: key,
            mpersona: globalState.persona?.mpersona,
            name: getCollectionName(key).toUpperCase()
          };
          allCollectionObj = [...allCollectionObj, myTestObj]
        }
      });
      let tagList = []
      topicsState?.topics &&
        topicsState.topics.forEach((t) => {
          if (t.mpersona === globalState.persona?.mpersona) {
            let tag = getIdentity(t);
            let index = tagList.findIndex((item) => item.id === tag);
            if (index !== -1) {
              if (
                new Date(tagList[index].last_vis_date) < new Date(t.last_visible_date)) {
                let updatearr = tagList.map((item) => {
                  if (item.id === tag) {
                    return { ...item, last_vis_date: t.last_visible_date }
                  }
                  else {
                    return item
                  }
                })
                tagList = updatearr
              }
            }
            else {
              let tagObj = {}
              tagObj = {
                "id": tag,
                "last_vis_date": t.last_visible_date ? t.last_visible_date : "1970"
              }
              tagList.push(tagObj)
            }
          }
        })
      tagList.sort((a, b) => {
        return new Date(b.last_vis_date) - new Date(a.last_vis_date)
      })
      let foundObj = [];
      if (tagList.length > 0) {
        let sortedList
        tagList.map((key, index) => {
          sortedList = allCollectionObj.find(item => {
            return item.key === key.id
          })
          if (sortedList) foundObj = [...foundObj, sortedList]
        })
      }
      if (foundObj && foundObj.length > 0)
        displayList = [...displayList, ...foundObj]
          setTopicDisplayList(displayList);
    } else {
      if (data) {
        setTopicDisplayList(listItems);
      }
    }
  }, [
    topicsState.topics,
    globalState.persona?.mpersona,
    globalState.displaying_archive,
    globalState?.prefs?.showFolders,
    globalState.prefs.labelNamesList,
    props.share,
    liveCacheState?.collectionState,
    JSON.stringify(countState.last_visible_date),
    listItems,
    countState,
    topicsState.unreadDisplay,
    topicsState.labelDisplay,
    topicsState.flagDisplay,
    topicsState.showTopic
  ]);

  const DisplayCollection = (props) => {
    return (
      <div>
        <div
          className="UI-topic_forwithpreview"
          key={props.collKey}
          // className="UI-collection"
          type={
            props?.collKey?.toUpperCase() === "PRIVATE_DIALOG"
              ? "dialog"
              : props.collKey
          }
          style={{
            backgroundColor: getHeaderBarColor(props.collKey)
          }}
          onClick={(e) => {
            e.preventDefault();
            toggleCollectionState(
              props?.collKey,
              globalState.persona?.mpersona
            );
          }}
        >
          <div
            className="avatar"
            style={{
              width: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <GetInitials
              str={getCollectionName(props?.collKey)}
              head_list={false}
              type="topic"
            ></GetInitials>
          </div>

          <div className="UI-topic-link-with-t">
            <div className="UI-topic-inner">
              <div className="UItopic-tdn hide-scrollbar">
                {getCollectionName(props?.collKey).toUpperCase()}
              </div>
            </div>
          </div>

          <div className="rgtContainer">
            <span className="mute-icon"></span>
            {/* this must be blank */}
            <span className="mute-icon"></span>
            {/* this must be blank */}
            {globalState?.prefs?.showFolders ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <BsFolderPlus style={{ width: "1.5em", height: "1.5em" }} />
              </div>
            ) : (
              <label className="UI-topics-icon">collection</label>
            )}
            <div className="UI-topic-count" type="collection">
              {collectionCount[props.collKey]?.unread &&
              collectionCount[props.collKey].unread > 0 ? (
                collectionCount[props.collKey].unread
              ) : (
                <></>
              )}
            </div>
          </div>
          <ClickOutsideDetector caller="iconDropDown">
            <div className="UI-icondropdown"></div>
          </ClickOutsideDetector>
        </div>
      </div>
    );
  };

  const DisplayFolderPanel = (props) => {
    let initials = getCollectionName(props.tag);
    let barColor =
      getHeaderBarColor(props.tag) || "var(--modal_footer_background_color)";

    return (
      <div
        className="UI-topic_forwithpreview"
        // className="UI-collection"
        type={props.tag.toLowerCase()}
        style={{
          backgroundColor: `${barColor}`,
          cursor: "pointer"
        }}
        onClick={(e) => {
          e.preventDefault();
          toggleCollectionState(props.tag, globalState.persona?.mpersona);
        }}
      >
        <div
          className="avatar"
          style={{
            width: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <GetInitials str={initials} head_list={false}></GetInitials>
        </div>
        <div className="UI-topic-link-with-t">
          <div className="UI-topic-inner" style={{ width: "100%" }}>
            <div className="UI-topic-tdn hide-scrollbar">
              <span>{props.tag.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div
          className="rgtContainer">
          <div>
            {
              (Object.keys(liveCacheState?.collectionState)?.length === 0)
                ? <BsFolder style={{ width: "1.5em", height: "1.5em" }} />
                :
                liveCacheState?.collectionState?.[props?.tag] &&
                  liveCacheState?.collectionState?.[props?.tag][globalState.persona?.mpersona] &&
                  liveCacheState?.collectionState?.[props?.tag][globalState.persona?.mpersona]
                    .open
                  ?
                  <BsFolderPlus style={{ width: "1.5em", height: "1.5em" }} />
                  : <BsFolderMinus style={{ width: "1.5em", height: "1.5em" }} />
            }
          </div>
          <div
            className="UI-topic-count"
            type="folder"
          >
            {globalState?.displaying_archive
              ? <></>
              : collectionCount[props.tag]?.unread &&
                collectionCount[props.tag].unread > 0
                ? collectionCount[props.tag].unread
                : <></>

            }
          </div>
        </div>
        <ClickOutsideDetector caller="iconDropDown">
          <div className="UI-icondropdown"></div>
        </ClickOutsideDetector>
      </div>
    );
  };

  useEffect(() => {
    const storedScrollTop = sessionStorage.getItem("listScrollTop");
    const parsedData = storedScrollTop ? JSON.parse(storedScrollTop) : null;
    if (parsedData && parsedData?.mpersona === globalState?.persona?.mpersona) {
      try {
        setScrollTop(parseInt(parsedData?.scrollTop));
      } catch (error) {
        console.error("Error parsing stored index:", error);
      }
    } else if (parsedData?.mpersona !== globalState?.persona?.mpersona) {
      sessionStorage.removeItem("listScrollTop");
      setScrollTop(0);
    }
  }, [globalState?.persona?.mpersona]);

  useEffect(() => {
    try {
      const storedMtopic = topicsState?.showTopic?.subscription?.mtopic;
      if (storedMtopic) {
        const chkMpersona =
          topicDisplayList.findIndex(
            (item) =>
              item.mpersona === topicsState?.showTopic?.subscription?.mpersona
          ) > -1;
        if (chkMpersona) {
          const index = topicDisplayList.findIndex(
            (item) => item.mtopic === storedMtopic
          );
          let placeToGo = index * itemHeight;
          const startIndex = Math.floor(scrollTop / itemHeight);
          const endIndex = Math.min(
            startIndex + Math.floor(autoSizerHeight / itemHeight),
            topicDisplayList.length
          );
          if (index >= startIndex && index < endIndex) {
            // Item is already visible, no need to scroll
            return;
          }
          if (index >= 0 && placeToGo >= 0) {
            let tmpScrollTop = Math.max(placeToGo);
            if (
              topicDisplayList.length - index <
              autoSizerHeight / itemHeight
            ) {
              tmpScrollTop = Math.max(
                placeToGo -
                  (Math.min(autoSizerHeight / itemHeight) -
                    (topicDisplayList.length - index)) *
                    itemHeight,
                0
              );
            }
            try {
              setScrollTop(parseInt(tmpScrollTop));
            } catch (error) {
              console.error("Error parsing index:", error);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error retrieving data from sessionStorage:", error);
    }
    // }
  }, [topicDisplayList, topicsState.showTopic]);

  useEffect(() => {
    // Restore scroll position and selected index after returning to the page
    const storedScrollTop = sessionStorage.getItem("listScrollTop");
    const parsedData = storedScrollTop ? JSON.parse(storedScrollTop) : null;
    if (parsedData && parsedData?.mpersona === globalState.persona?.mpersona) {
      try {
        setScrollTop(parseInt(parsedData.scrollTop));
      } catch (error) {
        console.error("Error parsing stored index:", error);
      }
    }
  }, []);

  const handleScroll = ({ scrollTop }) => {
    setScrollTop(scrollTop);
  };

  useEffect(() => {
    const dataToStore = {
      scrollTop: scrollTop,
      mpersona: globalState.persona?.mpersona
    };
    sessionStorage.setItem("listScrollTop", JSON.stringify(dataToStore));
    // sessionStorage.setItem("listScrollTop", scrollTop);
  }, [scrollTop]);

  let content = useMemo(() => {
    const rowRenderer = ({ index, key, style }) => {
      const item = topicDisplayList[index];
      const itemKey = item.mtopic;
      return (
        <div
          key={key}
          // key={itemKey}
          style={{
            ...style,
            overflow: "hidden",
            marginBottom: "5px"
          }}
        >
          {globalState.persona?.mpersona === item.mpersona ? (
            // assignCollection(item) &&
            item.id?.includes("collection_") ? (
              <DisplayCollection key={item.key} collKey={item.key} />
            ) : // <DisplayCollection collKey={item.key} />
            item.id?.includes("folder_") ? (
              <DisplayFolderPanel key={item.folderTag} tag={item.folderTag} />
            ) : (
              // <DisplayFolderPanel tag={item.folderTag} />
              <UITopic
                key={index}
                sub={item}
                share={props.share}
                togglePin={togglePin}
                scrollTop={scrollTop}
              ></UITopic>
            )
          ) : null}
        </div>
      );
    };

    return (
      <div
        className={
          globalState.showPersonaScreen || globalState.showWorldIDScreen
            ? "UI-topic-list-container-hidden"
            : "UI-topic-list-container"
        }
      >
        <div id="list_holder" className="UI-topic-list">
          <div
            style={{
              position: "relative",
              maxHeight: "100%",
              height: "inherit"
            }}
          >
            {topicDisplayList.length > 0 ? (
              // &&
              <AutoSizer onResize={handleAutoSizerResize}>
                {({ height, width }) => (
                  <List
                    overscanRowCount={10}
                    rowCount={topicDisplayList.length}
                    rowHeight={itemHeight}
                    width={width}
                    height={height}
                    rowRenderer={rowRenderer}
                    onScroll={handleScroll} // attach onScroll handler to the List component
                    scrollTop={scrollTop}
                  ></List>
                )}
              </AutoSizer>
            ) : topicDisplayList.length <= 0 && topicsState.unreadDisplay ? (
              <p className="info-message">
                No topics with unread messages on this persona.
                <br />
                Clear filter at the bottom of the screen to display other
                topics.
              </p>
            ) : topicDisplayList.length <= 0 && topicsState.labelDisplay ? (
              <p className="info-message">
                <span>No topics labelled </span>
                <span style={{ fontWeight: "bold" }}>
                  {topicsState.labelDisplay}
                </span>
                <span> on this persona.</span>
                <br />
                Clear filter at the bottom of the screen to display other
                topics.
              </p>
            ) : topicDisplayList.length <= 0 && topicsState.flagDisplay ? (
              <p className="info-message">
                <span>No topics are starred on this persona.</span>
                <br />
                Clear filter at the bottom of the screen to display other
                topics.
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        {activeAreaButton(globalState?.displaying_archive ? "none" : "")}
      </div>
    );
  }, [
    activeAreaButton,
    globalState?.displaying_archive,
    globalState.persona?.mpersona,
    globalState.showPersonaScreen,
    globalState.showWorldIDScreen,
    globalState?.prefs?.clientStar,
    topicDisplayList,
    props.share,
    scrollTop,
    togglePin
    // topicsState.flagDisplay,
    // topicsState.labelDisplay
  ]);

  return content;
};

export default React.memo(UITopicList, (prevProps, nextProps,) => {
  return true; //isEqual(prevProps, nextProps);
});


